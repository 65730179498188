import React, { useState } from "react";
import Root from "./Root";

const About = () => {
  return (
    <Root isAbout={true}>
      <h1>This is about page</h1>
    </Root>
  );
};

export default About;
