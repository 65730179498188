import { AppBar, Container, makeStyles, Toolbar } from "@material-ui/core";
import TurnedInIcon from "@material-ui/icons/TurnedIn";
import BookIcon from "@material-ui/icons/Book";
import Mail from "@material-ui/icons/Mail";
import Phone from "@material-ui/icons/Phone";
import Close from "@material-ui/icons/Close";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Cart from "@material-ui/icons/ShoppingCart";
import React, { useState } from "react";
import TopMenuButton from "./TopMenuButton";
import MainAppBar from "../menu/MainAppBar";
import Menu from "../menu/Menu";
import { useEffect } from "react";

const useStyle = makeStyles((theme) => ({
  grow: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexGrow: 1,
    },
  },
  toolbar: {
    minHeight: "35px",
  },
  topAds: {
    backgroundColor: theme.palette.secondary.main,
    position: "relative",
  },
  adsLink: {
    textDecoration: "none",
    fontSize: "22px",
    color: "#fff",
    fontWeight: "bold",
    margin: "0 auto",
    padding: "14px 0",
    textAlign: "center",
    position: "relative",
    display: "block",
    textAlign: "center",
    letterSpacing: "3px",
    width: "90%",

    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));
function TopMenu({
  onClicks,
  width,
  hidemenu,
  hidemenu2,
  hideOnScroll,
  showMenu,
  isWidthUp,
  topAdsData,
  contactInfo,
  allLinks,
}) {
  const classes = useStyle();
  const [showAds, setshowAds] = useState(topAdsData.visible);

  useEffect(() => {
    setshowAds(topAdsData.visible);
  }, [topAdsData]);
  return (
    <div style={{ height: "160px" }}>
      {showAds && (
        <div className={classes.topAds}>
          <a
            href={topAdsData.link}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.adsLink}
          >
            {" "}
            {topAdsData.title}
          </a>
          <div onClick={() => setshowAds(false)}>
            <Close
              style={{
                position: "absolute",
                top: "5px",
                right: "10px",
                color: "#fff",
                cursor: "pointer",
              }}
            />
          </div>{" "}
        </div>
      )}

      <div style={{ position: "relative" }}>
        <div className={classes.grow}>
          <AppBar
            position="static"
            style={{ background: "transparent" }}
            elevation={0}
          >
            <Container maxWidth="lg">
              <Toolbar variant="dense" className={classes.toolbar}>
                <TopMenuButton
                  onClick={() =>
                    (window.location.href = `tel:${contactInfo.number}`)
                  }
                  Icon={Phone}
                  title={contactInfo.number}
                />
                <TopMenuButton
                  onClick={() =>
                    (window.location.href = `mailto:${contactInfo.email}`)
                  }
                  Icon={Mail}
                  title={contactInfo.email}
                />

                <div className={classes.grow} />

                <TopMenuButton
                  onClick={() => window.open(allLinks.ticketOpen, "_blank")}
                  Icon={Mail}
                  title="Open Tickets"
                />
                <TopMenuButton
                  onClick={() => window.open(allLinks.tutorial, "_blank")}
                  Icon={TurnedInIcon}
                  title="Tutorial"
                />
                <TopMenuButton
                  onClick={() => window.open(allLinks.blog, "_blank")}
                  Icon={BookIcon}
                  title="Blog"
                />
                <TopMenuButton
                  onClick={() => window.open(allLinks.offers, "_blank")}
                  Icon={LocalOfferIcon}
                  title="Offers"
                />
                {/* <TopMenuButton
              Icon={Cart}
              onClick={() =>
                window.open(
                  "https://coxhost.com/clients/cart.php?a=view",
                  "_blank"
                )
              }
              title="0 Items"
            /> */}
              </Toolbar>
            </Container>
          </AppBar>
        </div>
        <MainAppBar
          onClicks={onClicks}
          position={hidemenu2 ? "static" : "fixed"}
          allLinks={allLinks}
        />
        {!hidemenu
          ? showMenu &&
            isWidthUp("md", width) && (
              <Menu hideOnScroll={!hideOnScroll} allLinks={allLinks} />
            )
          : null}
      </div>
    </div>
  );
}

export default TopMenu;
